export default function Team () {

  return (
    <>
    <header className="navbar navbar-expand-lg fixed-top white py-3">
        <div className="container">
          <div style={{display: 'none'}} className="form-check form-switch mode-switch order-lg-2 me-3 me-lg-4 ms-auto" data-bs-toggle="mode">
            <input className="form-check-input" type="checkbox" id="theme-mode" />
            <label className="form-check-label" htmlFor="theme-mode"><i className="ai-sun fs-lg" /></label>
            <label className="form-check-label" htmlFor="theme-mode"><i className="ai-moon fs-lg" /></label>
          </div>
          <div className="form-check form-switch mode-switch order-lg-2 me-3 me-lg-4 ms-auto">
            <a className="btn btn-icon btn-md btn-light btn-twitter rounded-circle me-1" href="https://twitter.com/sweetshopmedia" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#fc7370" width="16" height="16" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
            </a>
            <a className="btn btn-icon btn-md btn-light btn-linkedin rounded-circle me-1" href="https://www.linkedin.com/company/sweetshop/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#fc7370" width="16" height="16" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>
            </a>
            <a className="btn btn-icon btn-md btn-light btn-instagram rounded-circle me-1" href="https://www.instagram.com/sweetshopmedia/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#fc7370" width="16" height="16" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
            </a>
            <a className="btn btn-icon btn-md btn-light btn-tiktok rounded-circle me-1" href="https://www.tiktok.com/@sweetshopmedia" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#fc7370" width="16" height="16" viewBox="0 0 2859 3333" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"><path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z"/></svg>
            </a>
            <a className="btn btn-icon btn-md btn-light btn-discord rounded-circle me-1" href="https://discord.com/invite/q3vaRsVjDA" target="_blank">
            <svg width="16" height="16"viewBox="0 -28.5 256 256" version="1.1" preserveAspectRatio="xMidYMid">
 
        <path d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z" fill="#fc7370" fill-rule="nonzero">
</path>
  
</svg>
            </a>
              <a className="btn btn-icon btn-md btn-light btn-twitter rounded-circle me-1" href="https://opensea.io/collection/thekandiclub" target="_blank"><img style={{height:"15px"}} src="os.png"/></a>

          </div>
          <button className="navbar-toggler ms-sm-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"><span className="navbar-toggler-icon" /></button>
          <nav className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav navbar-nav-scroll me-auto" >
              <li className="nav-item" ><a href="https://www.sweetshopmedia.com/" className="nav-link text-nowrap nav-edit" >HOME</a></li>
              <li className="nav-item"><a href="https://www.sweetshopmedia.com/agency" className="nav-link text-nowrap nav-edit">AGENCY</a></li>
              <li className="nav-item"><a href="#" className="nav-link text-nowrap nav-edit">TEAM</a></li>
              <li className="nav-item"><a href="https://www.sweetshopmedia.com/drops" className="nav-link text-nowrap nav-edit">DROPS</a></li>
            </ul>
          </nav>
        </div>
      </header>


<section className="container my-md-3 my-lg-5 dark-mode py-5">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4">
          {/* Item */}
          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="Goose_B&W.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                        <img src="Angus_NFT.png" className="rounded-3 nft-logo"  alt="NFT_Logo" />
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">Digital marketing entrepreneur with a tech and influencer background. Clients include Lego, Universal, Sony, Napster, Extensive black book of talent, celebs and industry contacts.</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/angusimlach/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://twitter.com/AngusImlach" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>
                    <a href="https://www.instagram.com/angus_imlach/?hl=en" target="_blank" className="btn btn-icon btn-secondary btn-instagram btn-sm bg-white me-2">
                      <i className="bx bxl-instagram" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">ANGUS IMLACH</h3>
                <p className="fs-sm mb-0">Founder AND CEO</p>
              </div>
            </div>
          </div>
          {/* Item */}
          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="morley_B&W.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                        <img src="main-logo.png" className="rounded-3 nft-logo"  alt="NFT_Logo" />
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">Seasoned music and brand entrepreneur, 15 years of marketing experience, PR and brand and culture strategy. Clients include Lego, Universal, and Sony</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/morleydave/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://twitter.com/morleydave" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">MORLEY DAVE</h3>
                <p className="fs-sm mb-0">FOUNDER AND CREATIVE DIRECTOR</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="Izzy_B&W.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                        <img src="Izzy_NFT.png" className="rounded-3 nft-logo"  alt="NFT_Logo" />
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">Experienced marketeer working on digital strategy for the likes of ellesse, Nokia, Napster, LEGO and more. Background in Sport.</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/izzy-imlach-a791b6136/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://twitter.com/Izzy_Imlach" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">IZZY IMLACH</h3>
                <p className="fs-sm mb-0">MARKETING</p>
              </div>
            </div>
          </div>


          {/* Item */}
          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="FREEGAS_B&W.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                        <img src="FREEGAS_NFT.png" className="rounded-3 nft-logo"  alt="NFT_Logo" />
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">Technical Product Manager and leading Web3.0 strategist. Experienced in dapp development. CEO of NiftyLabs. NFT investor and several launches.</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/jonahkitay/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://twitter.com/0xFREEGAS" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">JONAH KITAY</h3>
                <p className="fs-sm mb-0">CTO</p>
              </div>
            </div>
          </div>
         {/* Item */}
         <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="needelman_B&W.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                        <img src="needelman_NFT.png" className="rounded-3 nft-logo"  alt="NFT_Logo" />
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">Solidity and Web3 developer focusing on smart contracts, dapps and websites. CTO of NiftyLabs. NFT investor and several launches.</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/alex-needelman-5b2570101/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://twitter.com/Needelman_" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">ALEX NEEDELMAN</h3>
                <p className="fs-sm mb-0">DEVELOPMENT</p>
              </div>
            </div>
          </div>


          {/* Item */}
          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="Jamie_Hole.jpg" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                        <img src="Jamie Gradient.png" className="rounded-3 nft-logo"  alt="NFT_Logo" />
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">Ex-International Marketing Director @ Universal Music for 15 years, with a proven track record and amazing industry black book of contacts.</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/jamieholewholeent/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">JAMIE HOLE</h3>
                <p className="fs-sm mb-0">HEAD OF PARTNERSHIPS</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="Theo_B&W.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                        <img src="Theo Blackledge NFT.png" className="rounded-3 nft-logo"  alt="NFT_Logo" />
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">World renowned digital artist with over 300k followers</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.instagram.com/galyosef/" target="_blank" className="btn btn-icon btn-secondary btn-instagram btn-sm bg-white me-2">
                      <i className="bx bxl-instagram" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">THEO BLACKLEDGE</h3>
                <p className="fs-sm mb-0">3D ART LEAD</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="Marco_B&W.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                        <img src="Marco_NFT.png" className="rounded-3 nft-logo"  alt="NFT_Logo" />
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">An established digital illustrator with a passion for comic culture. 100k following.</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.instagram.com/mastermindsconnect/" target="_blank" className="btn btn-icon btn-secondary btn-instagram btn-sm bg-white me-2">
                      <i className="bx bxl-instagram" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">MARCO THE ARTIST</h3>
                <p className="fs-sm mb-0">ILLUSTRATION DIRECTOR</p>
              </div>
            </div>
          </div>


          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="Ross_Patel.jpg" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                        <img src="Ross Gradient.png" className="rounded-3 nft-logo"  alt="NFT_Logo" />
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">Run many high profile, large scale events across multiple territories. Managed a number of  large underground music acts.</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/rosspatel/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">ROSS PATEL</h3>
                <p className="fs-sm mb-0">EVENTS DIRECTOR</p>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="container my-md-3 my-lg-5 dark-mode">
        <h2 className="h1 text-center pt-1 pb-3 mb-3 mb-lg-4">Advisors</h2>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4">
    
        <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="CHRIS DEERING .jpg" style={{width:"100%"}} className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/cdeering/?originalSubdomain=uk" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://en.wikipedia.org/wiki/Chris_Deering" target="_blank" className="btn btn-icon btn-secondary btn-home btn-sm bg-white me-2">
                      <i className='bx bxs-home'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">CHRIS DEERING</h3>
                <p className="fs-sm mb-0">Ex President, Playstation</p>
              </div>
            </div>
          </div>

          
          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="Ivor.png"  style={{maxHeight:"415px"}} className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/ivor-heller-2b999b44/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://www.afcwimbledon.co.uk/news/2021/august/this-is-the-greatest-moment-in-the-entire-history-of-our-club/" target="_blank" className="btn btn-icon btn-secondary btn-home btn-sm bg-white me-2">
                      <i className='bx bxs-home'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">IVOR HELLER</h3>
                <p className="fs-sm mb-0">C.D - AFC Wimbledon</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="RICHARD  POWAZYNSKI.png" style={{width:"100%", height:"100%"}} className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/richardpowazynski/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://woodiesofficial.com/" target="_blank" className="btn btn-icon btn-secondary btn-home btn-sm bg-white me-2">
                      <i className='bx bxs-home'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">RICHARD POWAZYNSKI</h3>
                <p className="fs-sm mb-0">CEO, WoodiesNFT</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="GARETH INGHAM.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/garethingham/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://urfeed.xyz/" target="_blank" className="btn btn-icon btn-secondary btn-home btn-sm bg-white me-2">
                      <i className='bx bxs-home'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">GARETH INGHAM</h3>
                <p className="fs-sm mb-0">CEO, urFeed</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="SVEN SANDAHL.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/sven-sandahl-2264015/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">SVEN SAANDAHL</h3>
                <p className="fs-sm mb-0">Serial Entrepreneur/Philanthropis</p>
              </div>
            </div>
          </div>
        
        </div>
      </section>

      <section className="container my-md-3 my-lg-5 dark-mode">
        <h2 className="h1 text-center pt-1 pb-3 mb-3 mb-lg-4">Partners</h2>
      
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4">
      

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="nifty-white.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/company/80100415" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://twitter.com/niftylabs" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>                    
                    <a href="https://niftylabs.dev/" target="_blank" className="btn btn-icon btn-secondary btn-home btn-sm bg-white me-2">
                      <i className='bx bxs-home'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">NIFTYLABS</h3>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="mastermind.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">

                    <a href="https://twitter.com/marcobernardart" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>   
                    <a href="https://www.youtube.com/channel/UCABNElqmEdN-6USQ2bwBjQA" target="_blank" className="btn btn-icon btn-secondary btn-youtube btn-sm bg-white me-2">
                      <i className="bx bxl-youtube" />
                    </a> 
                    <a href="https://www.instagram.com/mastermindsconnect/" target="_blank" className="btn btn-icon btn-secondary btn-instagram btn-sm bg-white me-2">
                      <i className="bx bxl-instagram" />
                    </a>   
                    <a href="https://mastermindsconnect.com/" target="_blank" className="btn btn-icon btn-secondary btn-home btn-sm bg-white me-2">
                      <i className='bx bxs-home'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">MASTERMINDS CONNECT</h3>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="TH3M.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">

                    <a href="https://twitter.com/th3mdotcom" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>     
                    <a href="https://th3m.com/" target="_blank" className="btn btn-icon btn-secondary btn-home btn-sm bg-white me-2">
                      <i className='bx bxs-home'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">TH3M</h3>
              </div>
            </div>
          </div>

        </div>

      </section>




  </>
  );
}
