import React, { useEffect, useState  } from 'react';
import { Howl } from "howler";
import Music from '../audio.mp3'

let sound = new Howl({
  src: [Music],
  loop: true,
  volume: 0.33
});

export default function Index () {

  var playing  = 0;

  function playSound () {
    
    if (playing === 0) {
      sound.play();
      playing = 1
      document.getElementById("audio-logo").src="pause.svg"
    } 
      else {
        sound.pause();
        playing = 0
      document.getElementById("audio-logo").src="play.svg"

        } 
      
      document.getElementById("removeHeight").classList.remove("maxHeight")
      document.getElementById("removeHeader").style.display="none";
};


  return (
    <>
<section id="removeHeight" className="maxHeight">
<header style={{cursor:"pointer"}} id="removeHeader" className="App-header" onClick={() => playSound()} >
        <footer className="header navbar navbar-expand-lg">
          <div className="py-5 align-items-center">
          <div className="d-flex justify-content-center pb-2 pt-lg-2 pt-xl-0">
                    <img style={{maxHeight:"400px"}} src="main-logo.png"/>
              </div>
              <div className="d-flex justify-content-center pb-2 pt-lg-2 pt-xl-0">
                    <a className="welcome w-100 btn btn-lg">CLICK OR TAP TO ENTER</a>
              </div>
          </div>
        </footer>
  </header>

          <main className="bg-main" id="down">
      <header className="dark-mode header navbar navbar-dark navbar-expand-lg navbar-sticky py-3">
      <div className="container px-3">
        <a href="/" className="navbar-brand"><img src="main-logo.png" style={{height: '60px'}} alt="logo" />
      </a>
        <div id="navbarNav" className="offcanvas offcanvas-end">
          <div className="offcanvas-header border-bottom">
            <h5 className="offcanvas-title"><img src="main-logo.png" style={{height: '30px'}} alt="logo" /></h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
          <a href="/about" className="nav-link btn  btn-sm fs-sm rounded d-lg-inline-flex" style={{color: 'white', marginRight: '20px'}}>ABOUT</a>
        </li>
        <li className="nav-item">
          <a href="/team" className="nav-link btn  btn-sm fs-sm rounded d-lg-inline-flex" style={{color: 'white', marginRight: '20px'}}>TEAM</a>
        </li>
        <li className="nav-item">
          <a href="/apply" className="nav-link btn  btn-sm fs-sm rounded d-lg-inline-flex" style={{color: 'white', marginRight: '20px'}}>APPLY</a>
        </li> 

            </ul>
          </div>    

        </div>
        <div className="cond-flex mb-4 mb-sm-0 nav-mobile" >
        <a href="https://twitter.com/Junguruverse" target="_blank" className="btn btn-icon btn-twitter rounded-circle">
              <i className="bx bxl-twitter"></i>
            </a>
            <a href="https://www.instagram.com/junguruverse/" target="_blank" className="btn btn-icon  btn-instagram rounded-circle">
              <i  className="bx bxl-instagram"></i>
            </a>
            <a href="https://www.tiktok.com/@junguruverse" target="_blank" className="btn btn-icon btn-tiktok rounded-circle" >
              <i className="bx bxl-tiktok"></i>
            </a>
            <button  id="stopButton" className="btn btn-icon rounded-circle" onClick={() => playSound()} >
                 <img id="audio-logo" style={{maxHeight:"27px"}} src="pause.svg"></img>
           </button>
        </div>

        <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
      </div>
      </header>
            <div className="App-header-Main">
            </div>
      </main>
      </section>
  </>
  );
}
